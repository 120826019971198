/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
}

.title {
  font-size: medium;
}

.participant-container {
  grid-column: 1/2;
  grid-row: 1/2;
  padding: 0 1em 0.5em 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;
}

.address-list {
  grid-row: 2/3;
  grid-column: 1/2;
}
.chat {
  grid-row: 2/3;
  grid-column: 1/2;
}
