/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.grid-container {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0 0;
  grid-template-areas:
    "empty header-content"
    "menu header-content";
  overflow: hidden;

  --cluster-spacer: 0.6em;
  --fine-line-width: 0.5px;
  --line-height: 18px;
  --corner-radius: 4px;
  --xlarge-text: 24px;
  --large-text: 16px;
  --mlarge-text: 14px;
  --medium-text: 12px;
  --small-text: 10px;
  --text-weight: 600;
  --blue-grey: #303859;
}

.menu {
  grid-area: menu;
  display: grid;
  position: relative;
  overflow: hidden;
}

.header-content {
  grid-area: header-content;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "header"
    "content";
  overflow-x: scroll;
  position: relative;
  -ms-overflow-style: none;  /* for Internet Explorer, Edge */
  scrollbar-width: none;  /* for Firefox */
}

.header-content::-webkit-scrollbar {
  display: none;
}

.header {
  grid-area: header;
  display: grid;
  grid-template-rows: auto 1fr;
}

.content {
  position: relative;
  grid-area: content;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}

.empty {
  position: relative;
  grid-area: empty;
  background: yellow;
  overflow: hidden;
  opacity: 0;
  font-size: var(--xlarge-text);
  display: grid;
  grid-template-rows: auto 1fr;
}

.scroll-bar {
  position: fixed;
  bottom: 0;
  height: 6px;
  border-radius: 6px;
  background-color: var(--bg-color-frame);
  cursor: pointer;
}


/*
-----------------------------------------------------------------------------------------------
 */


.times {
  font-size: var(--large-text);
  line-height: 160%;
  position: relative;
  grid-column: 1;
  grid-row: 3;
}

.time {
  position: absolute;
  width: 100%;
  text-align: right;
  color: var(--fg-color-greyed);
  padding-top: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
}

.time-lines {
  position: relative;
  grid-column: 1;
  grid-row: 3;
  width: 100%;
}
.time-line {
  position: absolute;
  width: 100%;
  border-top: 1px dashed var(--fg-color-greyed);
}
.time-line-soft {
  position: absolute;
  width: 100%;
  opacity: 0.5;
  border-top: 0.5px dashed var(--fg-color-greyed);
}
.target-time-line {
  position: absolute;
  left: -20px;
  right: 0;
  border-top: 1px dashed var(--fg-color-greyed);
  /*transform: scaleX(2);*/
}
.target-icon {
  position: absolute;
  left: 40px;
  stroke: var(--fg-color-greyed);
  fill: var(--fg-color-greyed);
  transform: translate(0, -50%);
  z-index: 100;
}

.current-time-container {
  position: relative;
  grid-row: 3;
  grid-column: 2;
  overflow: hidden;
}
.current-time-line {
  position: absolute;
  left: 3px;
  width: 100%;
  height: 1%;
  border-top: 2px solid var(--fg-color-frame);
}
.current-time-indicator {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: var(--fg-color-frame);
  transform: translate(0, -2.5px);
}
.current-time {
  position: absolute;
  left: 0;
  color: var(--fg-color-frame);
  font-size: var(--xlarge-text);
  font-weight: var(--text-weight);
  line-height: var(--line-height);
  text-align: center;
  transform: translate(0, -50%);
  background-color: #090f2380;
  z-index: 100;
}

.or-area {
  grid-row: 1;
  background-color: var(--bg-color-dark1);
  margin-right: var(--cluster-spacer);
  display: grid;
  grid-template-rows: auto;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

.cluster-header {
  grid-row: 1;
  background-color: var(--bg-color-dark3);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
  border-radius: 6px;
  margin-right: var(--cluster-spacer);
}
.or-header {
  grid-row: 2;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  font-size: var(--xlarge-text);
  line-height: 90%;
  margin-right: var(--cluster-spacer);
  padding: 0 0;
}
.or {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  cursor: pointer;
}

.or-items-outer {
  grid-row: 2;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  margin-right: var(--cluster-spacer);
}

.or-items {
  position: relative;
  margin: 0 8px;
  overflow: hidden;
}
.or-item-background {
  background-color: #121930;
  border-radius: var(--corner-radius);
}
.or-item-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}
.or-item {
  position: absolute;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
}
.or-item-marker {
  position: absolute;
  width: 0;
  height: 0;
  right: 2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--blue-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 170;

}
.or-item-marker-text {
  right: 2px;
  transform: translate(7px, 0px);
  font-size: 8px;
  font-weight: 700;
  color: var(--bg-color-dark1);
}

.motion:before {
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    filter: blur(10px);
}
