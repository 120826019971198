/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: var(--padding-std);
  padding-bottom: 0;

  height: 100%;
  min-height: 0;
}

.list {
  grid-row: 2;
  flex: 1;
  margin-top: var(--padding-std);
  height: calc(100% - var(--padding-std));
  min-height: 0;
}

.empty {
  font-size: large;
  color: var(--fg-color-greyed);
  margin: auto;
}
