/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.phone {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: fill;
}

.app {
  top: 15%;
  left: 8%;
  width: 85%;
  height: 71%;
  position: absolute;
  border: none;
}
