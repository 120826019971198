/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;
}

.counter {
  position: absolute;
  top: 0%;
  left: -5px;

  border-radius: 50%;
  background-color: var(--bg-color-frame);
  color: var(--fg-color-bright);

  aspect-ratio: 1;
  width: 13pt;
  height: 13pt;

  font-size: small;
  text-align: center;
  vertical-align: middle;
}
