/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.page {
  grid-row: 1/3;
  grid-column: 1;
  z-index: 1;
}

.image-container {
  width: 100%;
  height: 0;
  flex: 1;
  display: block;
  position: relative;
}
.image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  touch-action: none;
}

.bottombar {
  width: 70%;
  display: flex;
  flex-direction: row;
  margin: 0 auto 2em auto;
}
