/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.requirements-container {
  padding: 0 0.24em 0.5em 0.24em;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}
