/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  min-height: 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'thread'
    'input-panel';
}

.thread-area {
  grid-area: thread;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}

.input-panel {
  grid-area: input-panel;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'input mainbutton';

  align-items: center;

  width: 100%;

  background-color: var(--bg-color-dark1);

  box-sizing: border-box;
  padding: 0px 10px 8px 15px;

  column-gap: 0.5em;

  font-size: large;

  position: relative;
}

.input-editor-frame {
  grid-area: input;

  margin-left: 10px;
  padding-left: 10px;
  padding-right: 4px;
  padding-top: 6px;
  padding-bottom: 6px;

  border: solid var(--fg-color-greyed) var(--outline-thickness-std);
  border-radius: 1em;

  display: flex;
  align-items: center; /* "height" along primary axis */
  justify-content: left; /* alignment on the primary axis */
  gap: 4px;
}

.input-control {
  width: 100%;
}

.input-control-textarea {
  padding: 0;
  margin: 0;

  border: none;

  color: var(--fg-color-frame);
  background: none;

  outline: none; /* Remove border in case of focus */

  overflow-wrap: anywhere; /* let break long words ... note: break-word did not have the right effect */
  font-size: large;
}

.input-panel-button {
  grid-area: mainbutton;
  height: 32px;
  display: flex;
  align-items: center; /* "height" along primary axis */
  justify-content: left; /* alignment on the primary axis */
}
.input-panel-button * {
  height: 100%;
  width: auto;
  padding: auto;
}

.cancelbutton {
  box-sizing: border-box;
  cursor: pointer;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: var(--fg-color-frame);

  padding: 0;
  width: calc(1.5em + 2px);
  height: 1.5em;
  margin-right: 2px;
}
