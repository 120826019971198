/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  margin: 2em;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.header-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: large;
}

.priority-container {
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 12%;
}
.priorities {
  display: flex;
  flex-direction: row;
  gap: 0.3em;
}

.infectious-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 27%;
}

.button-container {
  margin: 0em 1.2em 1.2em 1.2em;
}
