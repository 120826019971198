/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  position: absolute;
  left: var(--padding-small);
  bottom: var(--padding-small);
  padding: var(--padding-small);
  font-size: small;
  background-color: #0000007f;
  border-radius: var(--radius-corner-small);
}
