/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.description-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4em;
}
.description-icon {
  fill: var(--fg-color-bright);
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}
.description {
  font-size: medium;
  cursor: default;
}
.long-description {
  font-size: medium;
  cursor: default;
}
.location {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px
}

.location-text {
  font-size: medium;
  padding: 0;
  margin: 0;
}
