.specialty-container {
  border-radius: 99px;
  padding: 0 8px;
  line-height: 60%;
}

.specialty-content {
  font-size: 8px;
  padding: 0;
  margin: 0;
}

/* White */
.specialty-white {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.9);
}


/* Green */
.specialty-green {
  color: rgba(0, 128, 0, 0.9); /* less light */
  background-color: rgba(0, 128, 0, 0.2); /* more light */
}

/* Yellow */
.specialty-yellow {
  color: rgba(255, 255, 0, 0.9);
  background-color: rgba(255, 255, 0, 0.2);
}

/* Orange */
.specialty-orange {
  color: rgba(255, 165, 0, 0.9);
  background-color: rgba(255, 165, 0, 0.2);
}

/* Purple */
.specialty-purple {
  color: rgba(231, 70, 231, 0.9);
  background-color: rgba(128, 0, 128, 0.2);
}

/* Violet */
.specialty-violet {
  color: #AD78F1;
  background-color: rgba(173, 120, 241, 0.15);
}

/* Blue */
.specialty-blue {
  color: rgba(114, 172, 238, 0.9);
  background-color: rgba(113, 123, 231, 0.2);
}

/* Cyan */
.specialty-cyan {
  color: rgba(0, 255, 255, 0.8);
  background-color: rgba(0, 255, 255, 0.2);
}
