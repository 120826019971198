/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.item-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5em;
}

.title {
  width: 50%;
  font-size: medium;
  text-align: end;
}

.clinic {
  font-size: medium;
}

.yes {
  font-size: medium;
  color: var(--fg-color-frame);
}

.no {
  font-size: medium;
  color: var(--fg-color-bright);
}
