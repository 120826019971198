/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.profile-picture {
  width: 48px;
  height: 48px;
}

.svg-icon {
  fill: var(--fg-color-bright);
  background-color: var(--bg-color-dark2);
  border-radius: 24px;
  width: 48px;
  height: 48px;
  padding: 5px;
}
