.item-base {
  box-sizing: border-box;
}

.item-base-text-match {
  opacity: 1;
}

.item-base-text-unmatch {
  opacity: 0.6;
}

.item-example-unmatch::before {
  content: '<<';
}
.item-example-unmatch::after {
  content: '>>';
}

.item-selection-unmatch::before {
  content: '<<';
}
.item-selection-unmatch::after {
  content: '>>';
}

.item-base-active {
  border: none; /* black 1px dotted; */
}

.item-base-debug {
  border: 1pt dotted var(--fg-color-bright2);
  padding: 1pt;
  margin: 1pt;
}
.item-base-debug.item-base-active {
  border-style: solid;
}
