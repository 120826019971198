/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1.2em;
  overflow-y: auto;
}

.title {
  font-size: x-large;
}
