/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.main-info-item {
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  width: calc(100% - 1em);

  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1em;
  width: calc(100% - 2em);
}
.header {
  margin: 0em 1em 0em 1em;
  width: calc(100% - 2em);

  /* color: var(--fg-color-frame); */
  font-size: large;
  text-align: center;
}
.header-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 0.4em;
}
.message-time {
  display: flex;
  flex-direction: column-reverse;
  text-align: right;
  font-size: small;
}

.buttons {
  margin-top: 0.7em;
}

.swiper-page {
  background: none;
  display: flex;
  justify-content: center;
}

.navigation-before svg {
  margin-left: -0.5em;
  fill: var(--fg-color-frame);
}
.navigation-before-disabled svg {
  margin-left: -0.5em;
  fill: var(--fg-color-greyed);
}
.navigation-next svg {
  margin-right: -0.5em;
  fill: var(--fg-color-frame);
}
.navigation-next-disabled svg {
  margin-right: -0.5em;
  fill: var(--fg-color-greyed);
}
