/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.title {
  font-size: x-large;
}

.confirm-container {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.confirmDisabled {
  color: var(--fg-color-greyed);
}

.confirm-button {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
