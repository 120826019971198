.tab-icon {
    height: 35px;
    width: 35px;
    fill: var(--fg-color-bright);
}

.tab-active {
    height: 35px;
    width: 35px;
    fill: var(--fg-color-frame);
}
