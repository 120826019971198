/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.inner-container {
  display: flex;
  flex-direction: column;
  min-height: 0;
  align-items: center;
  overflow-y: auto;
}

.title {
  font-size: x-large;
  font-weight: bold;
  margin-bottom: 1em;
  margin-right: auto;
  padding: 0 2em;
}

.item {
  width: 300px;
  margin: 0.3em;
}
