body {
  background-color: var(--bg-color-dark1);
  overflow: auto;
}

.app-frame {
  min-width: 320px;
  max-width: 400px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'top-status'
    'messenger';
}
@media (max-width: 1200px) {
  .app-frame-w-dashboard {
    display: grid;
    min-width: 900px;
    min-height: 400px;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 310px;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'top-status top-status'
      'dashboard messenger';
  }
}
@media (min-width: 1200px) {
  .app-frame-w-dashboard {
    display: grid;
    min-width: 900px;
    min-height: 400px;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 410px;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'top-status top-status'
      'dashboard messenger';
  }
}
.app-frame-dashboard {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'dashboard';
}

.app-dashboard {
  width: 100%;
  height: 100%;
  grid-area: dashboard;
}

.app-messenger {
  grid-area: messenger;
  height: 100%;
  min-height: 0;
}

.top-status {
  grid-area: top-status;
}
