/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.title {
  font-size: x-large;
  font-weight: bold;
  margin-bottom: 1em;
  margin-right: auto;
  padding: 0 2em;
}

.spacer {
  flex: 1;
}
