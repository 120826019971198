/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  margin: 0.5em 20px 0.5em 20px;
}

.header-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.title {
  font-size: x-large;
  font-weight: bold;
  margin-bottom: 0.8em;
}
.order {
  font-size: x-large;
  color: var(--fg-color-frame);
  margin-bottom: 0.1em;
}
.order-comment {
  font-size: large;
  color: var(--fg-color-greyed);
}

.field-container {
  width: 100%;
  height: 100%;
  margin: 0.5em 0 0.5em 0;
}
.content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  gap: 1em;
  padding: 0.5em 0 0.5em 0;
}
.field {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.field-name {
  width: 36%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}
.field-value {
  color: var(--fg-color-frame);
}

.button {
  width: 75%;
  margin: 1.5em 0 0.5em 0;
}
