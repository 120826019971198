/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.outer-container {
  width: 100%;
  height: 100%;
  min-height: 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
}

.shade {
  grid-column: 1;
  grid-row: 1;
  background-color: #000000c0;
  z-index: 1;
}
