/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.2em;
}
.container {
  padding: 0.5em;
  width: 100%;
  cursor: default;
  border-radius: var(--radius-corner-std);
  display: flex;
  flex-direction: column;
}
.container:active {
  background-color: var(--fg-color-dark3);
}
.highlight {
  color: var(--fg-color-frame);
}
.greyed {
  color: var(--fg-color-greyed);
}
.challenge {
  color: var(--fg-color-warning);
}

.lower {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content 1fr;
}

.induction {
  justify-self: center;
}

.countdown-timer {
  height: 4em;
}
.patient-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5em;
}

.emergency {
  color: var(--fg-color-emergeny);
}
