/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 3em;
  margin-right: 3em;
}
