/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.context-action-defined {
  text-decoration: underline;
  cursor: pointer;
}
.context-action-defined::after {
  text-decoration: underline;
}
