/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.positive {
  width: 24px;
  fill: var(--fg-color-frame);
}
.negative {
  width: 24px;
  fill: var(--fg-color-female);
}
.neutral {
  width: 24px;
  fill: var(--fg-color-bright);
}

.warn {
  color: var(--fg-color-warning);
}
