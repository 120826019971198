.container {
  margin-top: 1.5em;
  margin-bottom: 0.5em;

  color: var(--fg-color-greyed);
}

.warning {
  color: var(--fg-color-warning);
}
.normal {
  color: var(--fg-color-greyed);
}
