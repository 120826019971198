/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-top: 0.5em;
}

.label {
  font-size: small;
  color: var(--fg-color-greyed);
}

.beds {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--padding-small);
  flex: 1;
}

.bed {
  width: 40%;
  max-width: 4em;
  height: 1.2em;
  border-radius: 0.6em;
}

.solid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.inset {
  font-size: small;
  color: var(--fg-color-greyed);
}

.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.bed-current {
  grid-column: 1;
  grid-row: 1;
  border-radius: 0.5em 0 0 0.5em;
}

.bed-next {
  grid-column: 2;
  grid-row: 1;
  border-radius: 0 0.5em 0.5em 0;
}

.free {
  background-color: var(--bg-color-bright);
}
.free-male {
  background-color: var(--bg-color-frame);
}
.free-female {
  background-color: var(--fg-color-female);
}
.free-diverse {
  background-color: var(--fg-color-warning);
}
.occupied {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--bg-color-bright);
}
.occupied-male {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--fg-color-frame);
}
.occupied-female {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--fg-color-female);
}
.occupied-diverse {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--fg-color-warning);
}
.blocked {
  border-style: solid;
  border-width: var(--outline-thickness-thin);
  color: var(--fg-color-greyed);
}
.blocked-soft {
  background-color: var(--fg-color-greyed);
}
.blocked-medium {
  background-color: var(--bg-color-dark3);
}

.detail-info {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  z-index: 1;

  border: solid 1pt var(--fg-color-frame-dark);
  border-radius: var(--radius-corner-std);
  padding: var(--padding-small);

  background: rgba(var(--bg-color-dark1-rgb), 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  display: flex;
  flex-direction: row;
}
.detail-info-meta {
  display: flex;
  flex-direction: column;
}
.detail-info-meta-line {
  display: flex;
  flex-direction: row;
}
.detail-info-additional {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.3em;
}
