/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.pie-circle {
  position: relative;
  border-radius: 100%;
}
.pie-circle svg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  transform: rotate(-90deg);
}

.back-circle {
  fill: none;
  stroke: var(--bg-color-dark2);
}
.front-circle {
  fill: none;
  stroke: var(--fg-color-frame);
}
.front-circle-hot {
  fill: none;
  stroke: var(--fg-color-female);
}

.front-circle-emergency {
  fill: none;
  stroke: var(--fg-color-emergeny)
}

.shiny {
  position: relative;
  border: none;
  overflow: hidden;
  border-radius: 100%;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    height: 25px;
    width: 8px;
    background: rgba(255,255,255,.8);
    filter: blur(4px);
    transform: translateX(20px) translateY(-2px) rotate(0deg);
    animation: shine 6s infinite linear;
    transform-origin: bottom;
  }
}

@keyframes shine {
  50% {transform: translateX(20px) translateY(0px) rotate(340deg); opacity: 1} /* Animate for the first 50% of the time (3s) */
  50.001% {transform: translateX(20px) translateY(0px) rotate(360deg); opacity: 0 }
  100% {transform: translateX(20px) translateY(0px) rotate(360deg); opacity: 0} /* Remain at the end state for the next 50% of the time (3s delay) */
}

.text-container {
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 4px;
  right: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #121930;
  border-radius: 100%;

}

.text-container p {
  margin: 0;
  padding: 0;
}
