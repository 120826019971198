.container {
  background: rgba(var(--bg-color-dark1-rgb), 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  border: solid 1pt var(--fg-color-frame-dark);
  border-radius: var(--radius-corner-std);
  padding: 0;

  transition: opacity var(--animation-time);
  opacity: 0;
}
.container2 {
  position: relative;
}
.inner-container {
  width: 100%;
  height: 100%;
  padding: calc(1.1 * var(--padding-std));

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-std);
}

.visible {
  opacity: 1;
}

.text {
  color: var(--fg-color-text);
  /* Enable the following to get only 1 line of undo text */
  /*   overflow-x:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
 */
}

.revert {
  grid-area: 'revert';
  color: var(--fg-color-frame);
  cursor: pointer;
}

.cancelbutton {
  position: absolute;
  top: var(--padding-small);
  right: var(--padding-small);

  width: 1em;
  height: 1em;
  background: none;
  padding: 0;
}
.cancelbutton span::after,
.cancelbutton span::before {
  background-color: var(--fg-color-greyed);
}
