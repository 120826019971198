/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 70px;
    gap: 10px;
}

.or {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    flex-direction: row;
}

.or_name {
    padding: 0;
    margin: 0;
    white-space: nowrap;
}

.priority-group-container {
    display: flex;
    align-items: center;
    align-content: space-between;
    background-color: #090f23;
    border-radius: 5px;
    padding: 0 15px;
    margin: 0 0 5px;
}

.priority-group-label-container {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    align-content: space-between;
}

.priority-group-description-container {
    margin-left: 20px;
}

.priority-group-description {
    margin: 0;
    padding: 0;
    font-size: 11px;
    text-align: center;
    color: var(--fg-color-greyed);
}
