/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  /* color: var(--fg-color-warning); */
  margin-top: 0;
  max-height: 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;

  /* ensure animation from top */
  align-items: flex-end;
  overflow-y: hidden;
  transition: all 1s ease-in-out;
}

.invisible {
  margin-top: 0;
  max-height: 0;
  opacity: 0;

  padding-bottom: 0em;
  border-bottom: 0.5px solid transparent;
}
.visible {
  margin-top: var(--padding-small);
  max-height: 100px;
  opacity: 1;

  padding-bottom: var(--padding-small);
  border-bottom: 0.5px solid var(--fg-color-greyed);
}

.message-container {
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: space-around;
  gap: var(--padding-std);
  padding-top: var(--padding-small);
  padding-bottom: var(--padding-small);
}

.button-grant {
  max-width: 200px;
}

.btn-close {
  flex-grow: 0;
  padding: 0;
}
