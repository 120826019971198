/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
}

.about-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: var(--bg-color-dark1);
}

.logo {
  max-width: 30%;
  max-height: 30%;
  flex-grow: 1;
}

.logo-info {
  width: 48px;
  height: 48px;
}

.info-section {
  margin-left: 0.5em;
  margin-right: 0.5em;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 1em;
}
.info-section-footer {
  flex-grow: 1;
}
.info-section-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;

  margin-top: 0.6em;
}

.version {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0.5em;
}

.support-text {
  font-size: large;
}

.support-infos {
  text-decoration: underline;
  margin-bottom: 1em;
}

.version-info-heading {
  display: block;
  font-weight: bold;
  margin-top: 0.6em;
}

.support-info-table {
  width: 100%;
}
.version-info-name,
.version-info-value {
  padding: 0;
  vertical-align: text-top;
}
.version-info-value {
  padding-left: 0.1em;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.top-spacer {
  flex: 1;
}

.middle-spacer {
  flex: 8;
}

.version-info-server {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  padding-top: 0.4em;
  padding-bottom: 0.2em;
}
.version-info-server-url {
  font-size: 70%;
}

.license {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
  padding-bottom: 0.2em;
}

.license-info-heading {
  text-decoration: underline;
}

.copyrightcontainer {
  display: block;
}
