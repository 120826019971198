/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  height: 100%;
  overflow-y: auto;

  position: relative;
}

.procedure-header {
  padding: 0.5em 1em 0.5em 1.5em;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-bottom: 1.5em;
}
.content-inner {
  padding: 0.5em 20px 0em 20px;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.content-addresses {
  padding: 0em 1.5em 0em 1.5em;
  height: 100%;

  grid-row: 1 / 2;
  grid-column: 1 / 2;
}
