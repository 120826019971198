/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  padding: 0 24px 0 24px;
  height: 70%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  row-gap: 1em;
}

.input {
  grid-row: 1;
  grid-column: 1;
}

.result-container {
  grid-row: 2;
  grid-column: 1;
}
.inner-result-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.6em 0 0.6em 0;
  gap: 1em;
  overflow-y: scroll;
}
.result {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  column-gap: 0.8em;
}
.icon {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  padding: 2px;
  background-color: var(--bg-color-dark2);
}
.profile {
  width: 48px;
  height: 48px;
}
.result-text-container {
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}
.name {
  overflow: hidden;
  flex-shrink: 1;
}
.role {
  color: var(--fg-color-greyed);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
}
