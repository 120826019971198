/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sub-container {
  display: flex;
  flex-direction: row;
  font-size: var(--medium-text);
  line-height: var(--line-height);
  gap: 5px;
  background-color: var(--bg-color-dark1);
}

.description {
  opacity: 0.5;
}
