.thread-item {
  margin: 0.5em;
}

.thread-item-sent .thread-item-message {
  background-color: var(--bg-color-dark2);
  border-radius: var(--radius-corner-std);
}

.thread-item-received .thread-item-message {
  background-color: var(--bg-color-dark2);
  border-radius: var(--radius-corner-std);
}

.thread-item-received {
  display: grid;
  grid-template-columns: 80fr 20fr;
  grid-template-rows: auto;
  grid-template-areas: 'message border';
}

.thread-item-sent {
  display: grid;
  grid-template-columns: 20fr 80fr;
  grid-template-rows: auto;
  grid-template-areas: 'border message';
}

.thread-item-message {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 0.1em;
  padding-top: 0.5em;

  grid-area: message;
  cursor: default;
}

.thread-item-message-clickable {
  cursor: pointer;
}
