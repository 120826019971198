/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.patient {
  margin-top: 0.3em;
}

.male {
  color: var(--fg-color-frame);
}

.female {
  color: var(--fg-color-female);
}

.diverse {
  color: var(--fg-color-warning);
}
.request-source {
  color: var(--fg-color-bright2);
  margin-left: 0.2em;
}
.request-source::before {
  content: '(';
}
.request-source::after {
  content: ')';
}
