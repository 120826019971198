/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.notification {
  position: relative;
}

.img-notification {
  transform: rotate(15deg); /* Rotate the bell a bit */
  height: 100%;
  padding: 4px;
}

.counter {
  position: absolute;
  top: 50%;
  right: 5%;

  border-radius: 50%;
  background-color: red;

  aspect-ratio: 1;
  width: 13pt;
  height: 13pt;

  font-size: small;
  text-align: center;
  vertical-align: middle;
}
