/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.outer-container {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}

.header {
  font-size: medium;
}
.warn {
  color: var(--fg-color-warning);
}

.scroll-element {
  font-size: 2pt;
}
