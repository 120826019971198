/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.1em;
  min-height: 0;
  overflow-y: auto;
  padding-top: 0.3em;
  padding-bottom: 0.8em;
}
