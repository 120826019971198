/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.user-info {
  display: flex;
  flex-direction: row;
  gap: var(--padding-std);
  align-items: center;
}

.user-info-details {
  display: flex;
  flex-direction: column;
}

.user-name {
  display: inline;
}

.user-employee-id {
  font-size: small;
}

.user-roles {
  font-size: small;
}

.user-image {
  height: 46px;
  width: 46px;
  min-width: 46px;
  padding: 4px;
}
