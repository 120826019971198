/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
  overflow-y: auto;
}

.option {
  padding: 0 2em;
  border-top: 0.5px;
  border-color: var(--fg-color-greyed);
  border-style: solid none none none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 3em;
  margin-right: 3em;
  background: none;
}
.option-top {
  padding: 0em 2em;
  display: flex;
  border-style: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 3em;
  margin-right: 3em;
  background: none;
}
.option-text {
  font-size: medium;
  color: var(--fg-color-frame);
  padding: 0.9em;
}
