.input-control-root {
  position: relative;
}

.input {
  /* Standard Input Box - Debug only */
  width: 100%;
  background-color: var(--bg-color-dark3);
  border: 1px solid black;
}

.input-editor-frame {
  padding-left: 10px;
  padding-right: 4px;
  padding-top: 6px;
  padding-bottom: 6px;

  border: solid var(--fg-color-greyed) var(--outline-thickness-std);
  border-radius: 1em;

  display: flex;
  align-items: center; /* "height" along primary axis */
  justify-content: left; /* alignment on the primary axis */
  gap: 4px;
}

.input-editor {
  /* The editing div */
  width: 100%;
  background: none;

  outline: none; /* Remove border in case of focus */

  color: var(--fg-color-frame);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'content';
}

.input-editor2 {
  /* The editing div */
  width: 100%;
  background: none;

  outline: 1pt solid green; /* Remove border in case of focus */

  color: var(--fg-color-frame);
}

.input-editor-common {
  grid-area: content;

  background: none;
  white-space: pre-wrap;

  outline: none; /* Remove border in case of focus */

  overflow-wrap: anywhere; /* let break long words ... note: break-word did not have the right effect */
  line-height: 1.2; /* ensure the preview and edit are rendered congruent - actually bad bractice but it works (https://iamvdo.me/en/blog/css-font-metrics-line-height-and-vertical-align) */
}

.cancelbutton {
  box-sizing: border-box;
  cursor: pointer;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: var(--fg-color-frame);

  padding: 0;
  width: calc(1.5em + 2px);
  height: 1.5em;
  margin-right: 2px;
}

.debug-div {
  font-size: 8px;
  background-color: darkgray;
  position: fixed;
  right: 0;
  top: 0;
  height: 50vh;
  overflow: auto;
  z-index: 2;
}
.debug-div pre {
  margin: 0;
}
