/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'icon title time'
    'icon sub-title time';
  column-gap: var(--padding-std);
}

.icon {
  grid-area: icon;
  position: relative;
}

.title {
  grid-area: title;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sub-title {
  grid-area: sub-title;
  color: var(--fg-color-greyed);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.sub-title-container {
  grid-area: sub-title;
  display: flex;
  flex-direction: column;
  color: var(--fg-color-greyed);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.time {
  grid-area: time;
  margin-top: 2px;
}

.id {
  font-size: 6pt;
}

.badge {
  position: absolute;
  top: 0%;
  left: -5px;

  border-radius: 50%;
  background-color: var(--bg-color-frame);
  color: var(--fg-color-bright);

  aspect-ratio: 1;
  width: 13pt;
  height: 13pt;

  font-size: small;
  text-align: center;
  vertical-align: middle;
}
