/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-color-dark1);
}

.image {
  width: 100%;
  height: 0;
  flex: 1;
  object-fit: contain;
}

.bottombar {
  width: 75%;
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding: 1em;
}
