/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;
  margin: 0.5em 20px 0.5em 20px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  margin: 0.5em 0 0.5em 0;
}

.title {
  font-size: x-large;
  font-weight: bold;
}
.icon-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.4em;
}
.icon {
  fill: var(--fg-color-bright);
  flex-shrink: 0;
}
.icon-description {
  font-size: medium;
  cursor: default;
}
.icon-description-highlight {
  color: var(--fg-color-frame);
  font-size: medium;
  cursor: default;
}

.path-container {
  margin: 0.5em 0 0.5em 0;
}
