/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.button {
  border: none;
  background-color: transparent;
  padding: 4px;
}

.icon {
  stroke: var(--fg-color-frame);
  fill: var(--fg-color-frame);
}
