/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.messenger {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  position: relative;
}

/* Selection should still allow the tab row to be visible */
.menu {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) 70px;
  pointer-events: none;
}
/* If the height is very small (e.g. due to an onscreen keyboard on a mobile phone), the selection shall stretch over the complete area */
@media only screen and (max-height: 560px) {
  .menu {
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
  }
}

.selection {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  pointer-events: all;
}
