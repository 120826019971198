/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  background-color: var(--bg-color-dark1);
  z-index: 9999;
}

.header {
  grid-row: 1;
  grid-column: 1;
  margin-top: 6px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
}
.btn-close {
  padding-left: 0;
}
