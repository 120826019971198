/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 2 * var(--padding-std));
  margin-left: var(--padding-std);
  margin-right: var(--padding-std);
  margin-top: var(--padding-std);
}

.title {
  margin-right: auto;
  margin-bottom: 1em;
  font-size: xx-large;
  font-weight: bold;
}

.content {
    display: flex;
    flex-direction: column;
    padding-left: var(--padding-std);
    width: 100%;
}

.sub-header {
  line-height: var(--line-height-desc);
  font-size: larger;
}

.description {
  line-height: var(--line-height-desc);
  width: calc(100% - 2 * var(--padding-std));
}
span > .description {
  color: var(--fg-color-frame);
}

.room-select {
  margin-left: calc(2 * var(--padding-std));
  margin-right: calc(2 * var(--padding-std));
  margin-top: var(--padding-std);
  width: calc(100% - 4 * var(--padding-std));
}

.location-list {
  margin-left: calc(2 * var(--padding-std));
  margin-right: calc(2 * var(--padding-std));
  width: calc(100% - 4 * var(--padding-std));

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: larger;
  gap: 0 var(--padding-std);
}
.location-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.location-list-item button {
  height: 1.5em;
  padding: 0;
  width: auto;
  aspect-ratio: 1;
  background: none;
}
