/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  position: relative;
  background-color: transparent;
  color: var(--fg-color-frame);
  border: var(--outline-thickness-std) solid var(--fg-color-bright);
  border-radius: var(--radius-corner-std);
  font-size: large;
}
.disabled-container {
  position: relative;
  background-color: transparent;
  color: var(--fg-color-greyed);
  border: var(--outline-thickness-std) solid var(--fg-color-greyed);
  border-radius: var(--radius-corner-std);
  font-size: large;
}

.select-container {
  padding: 0.4em 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-text {
  padding-left: 0.5em;
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.item-container {
  position: fixed;
  z-index: 2;
  bottom: 4vh;
  display: flex;
  flex-direction: column-reverse;
}

.spacer {
  flex: 1;
}

.item-sub-container {
  flex-shrink: 0;
  max-height: 85vh;
  padding: 0.4em 0;
  color: var(--fg-color-bright);
  border: 0.5px solid var(--fg-color-greyed);
  border-radius: var(--radius-corner-std);

  background: rgba(var(--bg-color-dark1-rgb), 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.item-scroll-container {
  height: 100%;
  overflow: auto;
}

.item {
  padding: 0.5em 0.5em;
  display: flex;
  flex-direction: row;
  gap: 0.3em;
}

.next {
  border-top: 0.5px solid var(--fg-color-greyed);
}

.icon-container {
  width: 24px;
  fill: var(--fg-color-bright);
}

.more {
  fill: var(--fg-color-bright);
}
.more-disabled {
  fill: var(--fg-color-greyed);
}
.less {
  fill: var(--fg-color-bright);
}
