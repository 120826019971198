/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: 100%;
  height: 0;
  flex: 1;
  object-fit: cover;
}
