.mainbutton {
  background-color: var(--bg-color-frame);
  border-radius: 999px;
  width: 32px;
  height: 32px;
}

.disabled {
  background-color: transparent;
}
