/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.page {
  grid-row: 1/3;
  grid-column: 1;
  z-index: 1;
}

.bottombar {
  width: 70%;
  display: flex;
  flex-direction: row;
  margin: 0 auto 2em auto;
}
