/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  font-style: italic;
  text-align: center;
  margin: 0.5em;
  margin-top: 1em;
}
