/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.shadow-container {
  grid-column: 1;
  grid-row: 2;
}

.scroll-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
}

.container {
  margin: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.text {
  min-height: 3em;
  font-size: large;
}

.no-bed-text {
  min-height: 3em;
  font-size: large;
  color: var(--fg-color-warning);
}
