/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  width: auto;
  height: 100%;
}

.children-container {
  position: relative;
  height: 100%;
}

.login-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirm-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
}
.confirm-iframe {
  position: absolute;
  left: 10%;
  top: 8%;
  width: 80%;
  height: 70%;
  background-color: var(--bg-color-dark1);
  border-style: none;
}
.confirm-instruction {
  position: absolute;
  left: 10%;
  top: 70%;
  width: 80%;
  height: 20%;
  padding-top: 10%;
  font-size: large;
  text-align: center;
  background-color: var(--bg-color-dark1);
}
