/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.header-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-end;
}
.header {
  font-size: large;
  color: var(--fg-color-frame);
}
.fold-button {
  height: 30px;
  width: 35px;
  padding: 0px;
  background: none;
}
.fold-button svg {
  fill: var(--fg-color-frame);
}
