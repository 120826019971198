/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.input-editor {
    pointer-events: none;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
