/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/


.container {
  position: fixed;
  bottom: 50px;
  left: 50%;
  padding: 5px 15px 15px 15px;
  border-radius: 10px;
  text-align: center;
  z-index: 99;
  backdrop-filter: blur(8px);
}
